<template>
  <div class="arrangements">
    <v-container>
      <v-row>
        <v-switch
          v-model="showFilters"
          label="Mostrar filtros"
          class="mt-0 mb-3"
          color="secondary"
          hide-details
        ></v-switch>
        <v-spacer></v-spacer>
        <v-btn class="mx-2" fab right color="secondary" small @click="reload">
          <v-icon> mdi-reload </v-icon>
        </v-btn>
      </v-row>
    </v-container>
    <v-card class="mb-2" v-if="showFilters">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <v-text-field
              label="Filtre por cedula o nombre de cliente"
              outlined
              hide-details
              dense
              append-icon="mdi-magnify"
              v-model="searchArrangementInput"
              clearable
              clear-icon="mdi-close-circle"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="py-0">
            <v-btn block color="secondary" @click="searchSale"> Buscar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-2" v-if="showFilters">
      <v-card-text>
        <v-row :justify="role == 'SUPERADMIN' ? 'start' : 'center'">
          <v-col cols="12" sm="6" md="4">
            <VueCtkDateTimePicker
              color="orange"
              v-model="datePiker.model"
              :label="datePiker.label"
              :range="true"
              :custom-shortcuts="datePiker.shortcut"
              :format="datePiker.format"
              :formatted="datePiker.formatted"
              inputSize="sm"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              outlined
              :items="users"
              item-text="name"
              item-value="id"
              label="Seleccione Usuario"
              no-data-text="No hay registros..."
              hide-details
              dense
              v-model="filters.user"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              outlined
              :items="this.$store.state.status.statusArrangements"
              label="Seleccione Estado"
              no-data-text="No hay registros..."
              hide-details
              dense
              v-model="filters.status"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="role == 'SUPERADMIN'">
            <v-select
              outlined
              :items="offices"
              item-text="name"
              item-value="id"
              label="Seleccione Oficina"
              no-data-text="No hay registros..."
              hide-details
              dense
              v-model="filters.office"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-btn block color="secondary" @click="searchFilters">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-2" v-if="showSummary">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-chip class="ma-2" color="blue" text-color="white">
              <v-avatar left class="blue darken-3">
                {{ arrangement_summary.total_arrangements }}
              </v-avatar>
              TOTAL
            </v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-chip class="ma-2 darken-1" color="blue" text-color="white">
              <v-avatar left class="blue darken-3">
                {{ arrangement_summary.total_pending }}
              </v-avatar>
              PENDIENTES
            </v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-chip class="ma-2 darken-1" color="green" text-color="white">
              <v-avatar left class="green darken-3">
                {{ arrangement_summary.total_done }}
              </v-avatar>
              HECHOS
            </v-chip>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Busqueda"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.$store.state.arrangements.arrangements.data"
        :search="search"
        hide-default-footer
        :loading="this.$store.state.arrangements.isLoading"
        loading-text="Cargando... Por favor espere"
        no-data-text="No hay registro"
        no-results-text="Lo sentimos no hay registro para su busqueda, intente con otro"
        :items-per-page="20"
      >
        <template v-slot:item.reference="{ item }">
          {{ item.loan_dress.loan.reference }}
        </template>
        <template v-slot:item.event_date="{ item }">
          {{ item.loan_dress.loan.event_date }}
        </template>
        <template v-slot:item.client="{ item }">
          {{
            item.loan_dress.loan.client.first_name +
            " " +
            item.loan_dress.loan.client.last_name
          }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            class="ma-2"
            :color="getStatusColor(item.status)"
            text-color="white"
            small
          >
            {{ getStatusLan(item.status) }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom v-if="item.status == 'PENDING'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="success"
                dark
                v-bind="attrs"
                v-on="on"
                @click="checkDone(item)"
                class="px-1"
              >
                mdi-check-bold
              </v-icon>
            </template>
            <span>ARREGLADO</span>
          </v-tooltip>
          <span v-else>N/A</span>
        </template>
      </v-data-table>
      <v-col cols="12" sm="12" md="12">
        <v-pagination
          v-model="pagination.current"
          :length="pagination.total"
          :total-visible="8"
          circle
          @input="nextPage"
        ></v-pagination>
      </v-col>
    </v-card>

    <!-- Dialog check done -->
    <v-dialog v-model="dialogCheckDone" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Seguro que desea marcar como hecho este arreglo?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancelCheckDone"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="checkDoneConfirmation"
            >Aceptar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_ARRANGEMENTS } from "@/store/arrangements";
import { SET_USERS } from "@/store/user/users";
import { SET_ALERT } from "@/store/alert";
import { SET_ARRANGEMENT_SUMMARY } from "@/store/summaries";
import { SET_OFFICES } from "@/store/offices";
import { mapGetters } from "vuex";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
export default {
  name: "Arrangements",
  data: () => ({
    showFilters: false,
    showSummary: false,
    search: "",
    dialogCheckDone: false,
    currentArrangement: null,
    headers: [
      {
        text: "# Contrato",
        align: "start",
        filterable: true,
        value: "reference",
      },
      {
        text: "Fecha Entrega",
        align: "start",
        filterable: true,
        value: "event_date",
      },
      {
        text: "Descripción",
        align: "start",
        filterable: true,
        value: "description",
      },
      {
        text: "Cliente",
        align: "start",
        filterable: true,
        sortable: false,
        value: "client",
      },
      { text: "Estado", value: "status", filterable: false, sortable: false },
      {
        text: "Acciones",
        value: "actions",
        filterable: false,
        sortable: false,
      },
    ],
    pagination: {
      current: 0,
      total: 0,
    },
    datePiker: {
      model: null,
      label: "SELECCIONE EL RANGO DE FECHA *",
      format: "YYYY-MM-DD",
      outputFormat: "YYYY-MM-DD",
      formatted: "ll",
      shortcut: [
        { key: "now", label: "Hoy", value: 0 },
        { key: "thisWeek", label: "Esta semana", value: "isoWeek" },
        { key: "last7Days", label: "Ultimos 7 dias", value: 7 },
        { key: "last30Days", label: "Ultimos 30 dias", value: 30 },
        { key: "thisMonth", label: "Este mes", value: "month" },
        { key: "lastMonth", label: "Ultimo mes", value: "-month" },
        { key: "thisYear", label: "Este año", value: "year" },
      ],
    },
    filters: {
      user: "",
      status: "",
      office: "",
    },
    searchArrangementInput: "",
    searchFiltersText: "",
    rules: {
      required: (value) => !!value || "Campo obligatorio.",
    },
    users: [],
    offices: [],
    arrangement_summary: null,
  }),
  created() {
    this.$store.dispatch("getArrangements");
    this.$store.dispatch("getUsersAll");
    this.$store.dispatch("getOfficesAll");
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === SET_ARRANGEMENTS) {
        this.pagination.current = state.arrangements.arrangements.current_page;
        this.pagination.total = state.arrangements.arrangements.last_page;
      }

      if (mutation.type === SET_USERS) {
        this.users = state.user.users.users;
      }
      if (mutation.type === SET_ARRANGEMENT_SUMMARY) {
        this.arrangement_summary = state.summaries.arrangement_summary;
        this.showSummary = true;
      }
      if (mutation.type === SET_OFFICES) {
        this.offices = state.offices.offices;
      }
    });
  },
  computed: {
    ...mapGetters(["getStatusLan", "getStatusColor"]),
    role() {
      return this.$store.state.user.user.role;
    },
  },
  methods: {
    reload() {
      this.searchArrangementInput = "";
      this.filters = {
        user: "",
        status: "",
      };
      this.datePiker.model = null;
      this.searchFiltersText = "";
      this.showFilters = false;
      this.showSummary = false;
      this.$store.dispatch("getArrangements");
    },
    searchSale() {
      if (this.searchArrangementInput == "") {
        this.$store.commit(SET_ALERT, {
          message: "Ingrese un valor de busqueda",
          type: "info",
        });
        return;
      }
      this.showFilters = false;
      this.$store.dispatch(
        "getArrangements",
        "search=" + this.searchArrangementInput + "&"
      );
    },
    searchFilters() {
      this.searchFiltersText = "";

      if (this.datePiker.model !== null) {
        this.searchFiltersText =
          "min_date=" +
          this.datePiker.model.start +
          "&max_date=" +
          this.datePiker.model.end +
          "&";
      }

      if (this.filters.user !== "") {
        this.searchFiltersText += "user=" + this.filters.user + "&";
      }

      if (this.filters.status !== "") {
        this.searchFiltersText += "status=" + this.filters.status + "&";
      }

      if (this.filters.office !== "") {
        this.searchFiltersText += "office=" + this.filters.office + "&";
      }

      if (this.searchFiltersText == "") {
        this.$store.commit(SET_ALERT, {
          message: "Ingrese un valor de busqueda",
          type: "info",
        });
        return;
      }

      this.showFilters = false;
      this.$store.dispatch("getArrangements", this.searchFiltersText);
      this.$store.dispatch("getArrangementSummary", this.searchFiltersText);
    },
    checkDone(arrangement) {
      this.dialogCheckDone = true;
      this.currentArrangement = arrangement;
    },
    checkDoneConfirmation() {
      this.$store.dispatch("updateArrangement", {
        id: this.currentArrangement.id,
        form: {
          status: "DONE",
          done_date: new Date().toISOString().substr(0, 10),
          user_id: this.$store.state.user.user.id,
        },
      });
      this.cancelCheckDone();
    },
    cancelCheckDone() {
      this.dialogCheckDone = false;
      this.currentArrangement = null;
    },
    nextPage() {
      this.$store.dispatch("nextPageArrangements", this.pagination.current);
    },
  },
  components: {
    VueCtkDateTimePicker,
  },
};
</script>
